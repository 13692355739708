code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #f0edee;
  font-family: "Oswald", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 0;
  min-height: 100%;
}

/* BEGIN HEADER STYLING */
header {
  padding: 5px 15%;
  background-color: #f0edee;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 9999;
  /* Gives Nav a nice floating look*/
  box-shadow: 0 0px 5px grey;
}

a {
  text-decoration: none;
}

#logo {
  flex: 1 1 30%;
}

#logo img {
  margin: 0;
  max-width: 100%;
}

header button {
  text-decoration: none;
  border: none;
}

header nav {
  flex: 1 1 49%;
  margin: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

header nav ul {
  margin: 2% 0;
  padding: 0;
  width: 100%;
  text-align: right;
}

nav ul li {
  display: inline;
  flex: 0 0 30%;
  padding: 5% 8%;
}

/* Subtle color change on text when hovered */
nav ul li:hover {
  color: #00adee;
}

nav ul button {
  border: none;
  color: black;
  font-weight: bold;
  font-size: 1.4vw;
  margin: 0 6%;
  text-decoration: none;
}

nav button:hover {
  color: #00adee;
}

/* END HEADER STYLING */

/* GENERAL PURPOSE STYLINGS */

section {
  justify-content: center;
  margin: 0 auto;
}

/* General stylings for Section headings */
.section-heading {
  margin: 0 13%;
  border-bottom: 5px solid #00adee;
  padding: 2%;
  text-align: left;
}

.section-heading h2 {
  font-size: 48px;
}

/* General stylings for section content */
.section-content {
  margin: 0 13%;
  margin-bottom: 2%;
  padding: 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.section-content h3 {
  font-size: 24px;
  line-height: 1.8;
}

.section-content p {
  font-size: 16px;
  line-height: 1.4;
}

/* Stylings for the social icons in case I use them elsewhere on the page */
.social-icons {
  flex: 1 1 25%;
  padding: 0 5%;
  margin: auto;
  flex-wrap: wrap;
  justify-items: center;
  align-items: center;
}

.social-icons ul {
  flex: 0 0 25%;
  text-decoration: none;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  align-items: center;
}

.social-icons a {
  margin: 0 5%;
  flex: 1 0 10%;
}

.social-icons ul li {
  text-decoration: none;
}

.social-icons img {
  max-width: 25%;
}

#contact-email {
  font-size: 24px;
  color: #00adee;
}

/* BEGIN HERO SECTION */
#hero {
  margin: 0 auto;
  width: 100%;
  position: relative;
}

#hero img {
  max-width: 100%;
  height: auto;
}

.hero-name {
  font-size: 4vw;
}
#hero-text {
  position: absolute;
  margin: auto;
  overflow: auto;
  top: 25%;
  left: 60%;
  text-align: right;
  font-size: 1.5vw;
  font-weight: bolder;
  color: white;
}

#hero-text span {
  color: #00adee;
}

/* BEGIN HOME SECTION */

/* BEGIN ABOUT ME SECTION */
#profile-image {
  flex: 0 1 28%;
  margin: auto;
}

#profile-image img {
  max-width: 100%;
  box-shadow: 0 1px 3px black;
}

#about-me-text {
  flex: 0 0 50%;
  font-size: 20px;
}

#about-me-text p {
  font-size: 22px;
}

#resume-link {
  text-decoration: none;
  color: #008bcc;
  font-size: 20px;
}

#about-me-text h3 {
  margin: 15px 0;
  font-size: 28px;
}

#about-me-text ul {
  padding: 22px;
}

/* BEGIN PORTFOLIO SECTION */
.hover-overlay:hover {
  background-color: #00adee;
}

.showcase-item {
  flex: 0 0 90%;
  padding: 5%;
  position: relative;
}

/* cap image width to 100% */
.showcase-item img {
  max-width: 100%;
}

.portfolio-item {
  margin: auto;
  flex: 0 0 40%;
  padding: 3%;
  position: relative;
}

.portfolio-item img {
  max-width: 100%;
}

#showcase-text h4 {
  font-size: 28px;
}

#showcase-text p {
  font-size: 16px;
}

.portfolio-item-text {
  padding: 2%;
  position: absolute;
  width: 33%;
  top: 60%;
  text-align: center;
  background-color: #f0edee;
}

.portfolio-item-text h4 {
  text-align: center;
  color: #00adee;
  font-size: 16px;
  letter-spacing: 1.25px;
}

.portfolio-item-text p {
  color: black;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  font-size: 12px;
  letter-spacing: 2px;
}

/* BEGIN CONTACT ME SECTION */
#contact-me {
  display: flex;
}
#contact-text {
  flex: 1 100%;
  margin: 1%;
}

#contact-text h3 {
  font-size: 26px;
}

#contact-text p {
  font-size: 20px;
}

#contact-form {
  margin: auto 0;
  padding: 0;
  flex: 0 0 45%;
}

#contact-form div {
  margin: 5%;
  text-align: left;
  display: flex;
}

#contact-form label {
  font-size: 20px;
}

#contact-form input {
  margin-left: auto;
  padding: 1%;
  font-size: 16px;
  width: 60%;
  border-radius: 6px;
  flex: 0 0 75%;
}

#contact-form textarea {
  margin-left: auto;
  padding: 1%;
  font-size: 16px;
  width: 60%;
  border-radius: 10px;
  flex: 0 0 75%;
}

#contact-error-text {
  color: red;
  font-size: 20px;
  margin: auto;
}

#contact-form button {
  padding: 1% 2%;
  font-size: 20px;
  border-radius: 10px;
  margin-left: 75%;
  width: 20%;
}

#contact-socials {
  margin: auto;
  text-align: center;
  padding: 0;
  flex: 0 0 45%;
}

#contact-socials h3 {
  margin: 6%;
}

/* BEGIN FOOTER STYLING */
footer {
  padding: 1%;
  margin: auto;
  margin-bottom: 0px;
  background-color: #f0edee;
  color: black;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 0 0px 5px grey;
}

footer p {
  font-size: 16px;
}

footer a {
  text-decoration: none;
  color: black;
  font-size: 16px;
}

#footer-icons {
  margin: auto 0;
  flex: 1 0 20%;
  text-align: center;
}

#copyright {
  flex: 0.5 0 33.33%;
  text-align: center;
}

footer #contact-info {
  flex: 0.5 0 20%;
  text-align: right;
  padding-right: 13.33%;
}

/* MEDIA QUERIES FOR SMALLER SCREEN SIZES*/
@media screen and (max-width: 1200px) {
  .section-heading h2 {
    font-size: 36px;
    letter-spacing: normal;
  }

  #profile-image {
    flex: 0 0 100%;
    max-width: 50%;
    margin: auto;
  }

  #about-me-text {
    flex: 0 0 100%;
  }

  .portfolio-item-text h4 {
    font-size: 10px;
    letter-spacing: normal;
    line-height: 1.6;
  }

  .portfolio-item-text p {
    font-size: 6px;
    letter-spacing: normal;
  }

  #showcase-text h4 {
    font-size: 14px;
    letter-spacing: normal;
  }

  #showcase-text p {
    font-size: 10px;
    letter-spacing: normal;
  }

  #contact-text h3 {
    font-size: 20px;
  }

  #contact-text p {
    font-size: 16px;
  }

  #contact-form button {
    width: 30%;
  }
}

@media screen and (max-width: 768px) {
  header {
    margin: 0 auto;
  }

  #logo {
    flex: 0 0 100%;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  #logo img {
    margin: 0 auto;
    padding: 0;
  }

  header nav {
    margin: 0;
    padding: 0;
    flex: 1 100%;
  }

  header nav ul {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  header nav ul li {
    margin: 0 5%;
    padding: 0;
    font-size: 14px;
  }

  header nav a {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }

  section {
    flex-wrap: wrap;
    margin: 0 auto;
  }

  .section-heading {
    margin: 0;
    padding: 0;
    flex: 0 0 99%;
    border-right: none;
    border-bottom: 3px solid #00adee;
  }

  .section-heading h2 {
    text-align: left;
    font-size: 28px;
    padding: 1% 0;
    text-align: center;
  }

  .section-heading h2 {
    font-size: 36px;
    letter-spacing: normal;
  }

  .section-content {
    flex: 0 1 100%;
  }

  .showcase-item h4 {
    font-size: 14px;
  }

  .showcase-item p {
    font-size: 10px;
  }

  .portfolio-item {
    flex: 1 1 100%;
  }

  .portfolio-item h4 {
    font-size: 14px;
  }

  .portfolio-item p {
    font-size: 10px;
  }

  #contact-form {
    flex: 1 100%;
  }

  #contact-form label {
    font-size: 14px;
  }

  #contact-form {
    margin: 5% auto;
    width: 100%;
  }

  #contact-socials {
    flex: 1 100%;
  }

  #copyright {
    flex: 1 100%;
    margin: 1% auto;
    text-align: center;
  }
  #footer-icons {
    flex: 1 100%;
    margin: 1% auto;
    text-align: center;
  }
  footer #contact-info {
    flex: 1 100%;
    margin: 1% auto;
    text-align: center;
  }
}
@media screen and (max-width: 575px) {
  #logo {
    margin: 0;
    padding: 0;
    flex: 1 100%;
  }

  #logo img {
    margin: 0;
    padding: 0;
  }

  header nav {
    margin: 0;
    padding: 0;
    flex: 1 100%;
  }

  header nav ul li a {
    margin: 0;
    padding: 0;
  }

  header nav button {
    margin: 0 2%;
  }

  .section-heading h2 {
    font-size: 24px;
  }

  #about-me-text h3 {
    font-size: 22px;
  }

  #about-me-text p {
    font-size: 20px;
  }

  #about-me-text ul {
    padding: 12px;
  }

  .contact h3 {
    font-size: 16px;
  }

  .contact p {
    font-size: 12px;
  }

  #contact-text h3 {
    font-size: 22px;
  }

  #contact-text p {
    font-size: 20px;
  }
}

@media screen and (max-width: 450px) {
  #showcase-text {
    top: 50%;
  }
}
